<!--
 * @Date: 2021-03-04 20:04:32
 * @LastEditors: frank
 * @LastEditTime: 2021-05-08 09:12:35
 * @FilePath: /shop_frontend/src/views/pageDecoration/imgNav.vue
 * @Description: Description
-->
<template>
  <section class="navModules">
    <a-row>
      <!-- <a-col span="8">
        <a-table
          :columns="columns"
          :data-source="info"
          :pagination="false"
          bordered
        >
          <span slot="action" slot-scope="text, record">
            <a-button type="link" @click="onEdit(record)">编辑</a-button>
          </span></a-table
        >
      </a-col> -->
      <a-col span="24">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="字体颜色">
            <!-- <input type="color" v-model="form.font_color" /> -->
            <div class="color__box"><colorPicker v-model="form.font_color" /></div>
          </a-form-model-item>
          <a-form-model-item label="背景颜色">
            <!-- <input type="color" v-model="form.background_color" /> -->
            <div class="color__box">
              <colorPicker v-model="form.background_color" />
            </div>
            
          </a-form-model-item>
          <a-form-model-item
            label="图片组"
            v-for="(item, index) in form.menu_items"
            :key="index"
          >
            <a-row>
              <a-col span="24">
                <a-upload
                  name="image"
                  list-type="picture-card"
                  :show-upload-list="false"
                  action="/api/nav_icons/"
                  :withCredentials="true"
                  :headers="headers"
                  @change="uploadChange(item, index, $event)"
                                    :beforeUpload="beforeUpload"
                >
                  <img
                    style="display: inline-block; width: 100px; height: 100px"
                    v-if="item.icon_url"
                    :src="item.icon_url"
                  />
                  <div v-else>
                    <div class="ant-upload-text">上传图片</div>
                    
                  </div>
                </a-upload>
                <span style="color: red;">建议上传图片尺寸100px*100px</span>
              </a-col>
              <a-col span="24">
                <a-row>
                  <a-col span="24"
                    ><div class="flex">
                      <span style="display: block; width: 4em">文本：</span
                      ><a-input
                        v-model="item.content"
                        class="w120"
                      ></a-input></div
                  ></a-col>
                  <a-col span="24"
                    ><div class="flex">
                      <span style="display: block; width: 4em">链接：</span
                      ><a-input
                        v-model="item.link"
                        class="w120"
                        allowClear
                      ></a-input
                      ><a-button
                        type="primary"
                        style="margin-left: 20px"
                        @click="openLink(item)"
                        >选择链接</a-button
                      >
                    </div></a-col
                  >
                  <a-col span="24">
                    <a-button type="danger" @click="deleteItem(index, item)"
                      >删除</a-button
                    >
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button @click="onAdd"> 添加列表 </a-button>
          </a-form-model-item>
          <a-form-model-item label="当前排序">
            <a-input v-model="form.index" class="w220" />
          </a-form-model-item>
          <!-- <a-form-model-item label="是否显示">
            <a-checkbox v-model="form.is_visible"></a-checkbox>
          </a-form-model-item> -->
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="onSubmit"> 保存 </a-button>
             <a-button type="danger" @click="onDelete" style="margin-left: 10px;"> 删除 </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
    </a-row>
    <chooseLink
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    ></chooseLink>
  </section>
</template>



<script>
import Cookies from "js-cookie";
export default {
  name: "imgNav",
  data() {
    return {
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 300,
        },
      ],
      form: {
        font_color: "",
        background_color: "",
        menu_items: [],
        is_visible: false,
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      rules: {},
      visible: false,
      current: {},
    };
  },
  props: {
    info: {
      type: Array,
    },
  },
  components: {
    chooseLink: () => import("@/components/chooseLink"),
  },
  computed: {
    headers() {
      return { "X-CSRFToken": Cookies.get("csrftoken") };
    },
  },
  watch: {
    info: {
      handler: function () {
        this.onEdit(this.info);
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    deleteItem(index) {
      this.form.menu_items.splice(index, 1);
    },
    openLink(item) {
      this.visible = true;
      this.current = item;
    },
    handleOk(list) {

      console.log(list, 'imgnav----')
      this.current.link = list[0] ? list[0].link : "";

      this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    },
    uploadChange(item, index, { file }) {
      if (file.status == "done") {
        item.icon_url = file.response.image;
        item.icon = file.response.id;
        this.form.menu_items.splice(index, 1, item);
      }
    },
    onAdd() {
      if (this.form.menu_items && this.form.menu_items.length > 0) {
        let index = this.form.menu_items[this.form.menu_items.length - 1].index;
        this.form.menu_items.push({
          index: index + 1,
          content: "",
          icon: "",
          link: "",
          icon_url: "",
        });
      } else {
        this.form.menu_items.push({
          index: 0,
          content: "",
          icon: "",
          link: "",
          icon_url: "",
        });
      }
    },
    onEdit(record) {
      this.form = { ...this.form, ...record };
    },
    onDelete () {
      this.$axios.delete('/nav_modules/' + this.form.id + '/').then(() => {
        this.$emit('refreshDel')
      })
    },
    onSubmit() {
      console.log(this.form, "form");
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let request = this.form.id
            ? this.$axios.put("/nav_modules/" + this.form.id + "/", this.form)
            : this.$axios.post("/nav_modules/", this.form);
          request.then((res) => {
            console.log(res);
            this.$message.success("操作成功");
            this.$emit("refresh");
          });
          //   alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
        beforeUpload(file, fileList) {
      const isLt500Kb = file.size / 1024 < 512;
      console.log(isLt500Kb, file.size / 1024);
      if (!isLt500Kb) {
        this.$message.error(file.name + "文件大小超出限制(512KB)，请修改后重新上传");
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="less">
</style>

